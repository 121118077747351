<template>
  <div>
    <v-carousel
      v-if="$vuetify.breakpoint.lgAndUp"
      hide-delimiters
      :dark="$vuetify.theme.dark"
      :light="!$vuetify.theme.dark"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <template v-for="(item, index) in produtosRecomendados">
        <v-carousel-item
          v-if="(index + 1) % columns === 1 || columns === 1"
          :key="index"
        >
          <v-row
            class="flex-nowrap"
          >
            <template v-for="(n,i) in columns">
              <template v-if="(+index + i) < produtosRecomendados.length">
                <v-col
                  :key="i"
                  cols="2"
                  lg="3"
                  xl="2"
                >
                  <card-produto
                    :produto="produtosRecomendados[+index + i]"
                  />
                </v-col>
              </template>
            </template>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
    <v-row
      v-else
      class="mb-6"
    >
      <v-col
        v-for="item in produtosRecomendados"
        :key="item.id"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        class="mb-6 py-0"
      >
        <card-produto
          :produto="item"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CategoriasStore, { BOOTSTRAP } from '@/store/modules/categorias'
  import { mapState, mapActions } from 'vuex'
  import CardProduto from './_cardProduto.vue'

  export default {
    components: {
      CardProduto,
    },
    data: () => ({
      model: null,
    }),
    computed: {
      ...mapState('categorias', ['loading', 'categorias']),
      ...mapState('produtoIndividual', ['produtosRecomendados']),
      columns () {
        if (this.$vuetify.breakpoint.xl) {
          return 6
        }
        if (this.$vuetify.breakpoint.lg) {
          return 4
        }
        if (this.$vuetify.breakpoint.md) {
          return 6
        }
        return 6
      },
    },
    async created () {
      if (!this.$store.hasModule('categorias')) { this.$store.registerModule('categorias', CategoriasStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('categorias', [BOOTSTRAP]),
    },
  }
</script>

<style>
.v-card {
  border-radius: 10px;
}
</style>

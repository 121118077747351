<template>
  <div>
    <base-loading v-if="loading" />
    <div v-else-if="( selecaoFazenda === null || selecaoFazenda === '' || selecaoFazenda === undefined )">
      <v-alert
        color="warning"
        icon="mdi-account-cowboy-hat"
        dark
        dismissible
      >
        <span><h2>{{ this.$store.state.user.roles[0].slug === 'tecnico' ? 'Por favor selecione um Produtor e uma Fazenda no canto superior direito da loja.' : 'Por favor selecione uma Fazenda no canto superior direito da loja.' }} </h2></span>
      </v-alert>
    </div>
    <div v-else-if="this.$store.getters['loja/catalogoFiltered'].length === 0 && !loading">
      <v-alert
        color="error"
        icon="mdi-cow"
        dark
        dismissible
      >
        <span> <h2>Não existem produtos cadastrados para a Fazenda selecionada.</h2></span>
      </v-alert>
    </div>
    <v-container
      v-else
      fluid
    >
      <v-row>
        <div
          v-if="(produto.desconto && produto.id_campanha === '-1' && produto.nomeCampanha === '') && ((this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade !== 2) || (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0))"
          style="
            position: absolute;
            left: 12px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 61px;
            height: 61px;
            background-color: #E40E0E;
            border-bottom-right-radius: 200px;
            border-top-left-radius: 15px;
          "
        >
          <p
            style="line-height: 104%; transform: rotate(-45deg); margin-left: 8px; color:white;z-index: 1; font-weight: bold; width: 50px; font-size: 18px;"
          >
            {{ produto.desconto }}% Off
          </p>
        </div>
        <div
          v-else-if="( this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2 ) || produto.preco <= 0"
          style="
            position: absolute;
            left: 12px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 61px;
            height: 61px;
            background-color: #ff8f00;
            border-bottom-right-radius: 200px;
            border-top-left-radius: 15px;
          "
        >
          <p
            class="mt-3"
            style="color:white;z-index: 1;transform: rotate(-45deg);font-weight: bold; font-size: 16px;"
          >
            Cotação
          </p>
        </div>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="9"
          xl="9"
        >
          <v-card
            elevation="1"
            min-height="480px"
            style="margin: 0px; padding-left: 30px; padding-right: 30px;"
          >
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <v-card-text>
                  <v-img
                    min-height="350"
                    max-height="350"
                    color="white"
                    contain
                    :src="showImage"
                  />
                </v-card-text>
                <v-slide-group>
                  <v-slide-item
                    v-for="image in produto.imagens"
                    :key="image"
                  >
                    <v-card
                      class="ma-1"
                      height="64"
                      width="70"
                      outlined
                      elevation="0"
                      @click="handleImageClick(image)"
                    >
                      <v-row
                        justify="center"
                        class="my-0 py-0 mt-1"
                      >
                        <v-scale-transition>
                          <v-img
                            max-height="56"
                            max-width="62"
                            height="56"
                            width="62"
                            contain
                            class="ma-0"
                            :src="formatImageLink(image)"
                          />
                        </v-scale-transition>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <div style="height: 320px;">
                  <div
                    style="font-size: 26px; color: #143693; font-weight: bold;"
                  >
                    {{ produto.nome }}
                  </div>
                  <div
                    v-if="produto.marca"
                    style="font-size: 18px;"
                  >
                    <span style="font-weight: 500;">Marca:</span> {{ produto.marca }}
                  </div>

                  <div
                    v-if="produto.desconto && produto.id_campanha === '-1' && produto.nomeCampanha === ''"
                    style="margin-top: 8px;"
                  >
                    Oferta válida: {{ moment(produto.data_inicio).format('DD/MM/YYYY') }} a {{ moment(produto.data_fim).format('DD/MM/YYYY') }}
                  </div>
                  <div style="margin-top: 12px; overflow: auto; text-align: justify; height: 180px; padding-right: 8px;">
                    {{ produto.descricao }}
                  </div>
                </div>
                <div style="display: flex; gap: 3px;">
                  <span><v-icon size="15">mdi-information-outline</v-icon></span>
                  <a
                    target="_blank"
                    @click="informacoes"
                  >
                    <p style="color: black; text-decoration: underline; font-weight: 500;">mais informações do produto</p>
                  </a>
                </div>
                <div style="height: 100px">
                  <div
                    v-if="produto.nome_campanha"
                    style="overflow: hidden; background-color: #143693; min-height: 28px; display: flex; justify-content: space-between; border-radius: 10px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          style="color:white; margin: 3px auto; text-align: center; font-weight: bold;"
                          v-bind="attrs"
                          v-on="on"
                          @click="handleCampanhaClick(produto)"
                        >
                          <v-icon
                            small
                            color="white"
                            class="mr-1"
                          >
                            mdi-tag
                          </v-icon>
                          {{ produto.nome_campanha }}
                        </p>
                      </template>
                      <span>Clique aqui para acessar os detalhes da campanha</span>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="(this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2) || produto.preco <= 0"
                    style="font-size: 14px; font-weight: bold;  color: #ff8f00;"
                    class="mb-2 text-subtitle-1"
                  >
                    Ao Solicitar uma cotação para este produto, o Clube lhe retornará o preço e as condições de compra do mesmo.
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="3"
          xl="3"
        >
          <v-card
            :class="$vuetify.breakpoint.mdAndDown && 'mt-10'"
            elevation="1"
            min-height="480px"
            style="margin: 0px; padding-left: 30px; padding-right: 30px;"
          >
            <v-row>
              <v-card-text style="min-height: 360px;">
                <v-card
                  justify="center"
                  class="mt-0"
                  elevation="0"
                  style="height: 150px; padding-top: 20px; width: 100%;"
                >
                  <div
                    v-if="produto.pedido_min"
                    style="color: #808080;"
                    class="mb-2 text-subtitle-1"
                  >
                    <v-icon
                      color="#183B94"
                    >
                      mdi-package-variant
                    </v-icon>
                    Mínimo de {{ produto.pedido_min }} unidade(s)
                    <v-divider
                      style="background-color: rgba(183, 194, 222, 1); height: 2px; max-height: 2px; margin-top: 5px;"
                    />
                  </div>
                  <div
                    v-if="produto.parcelamento && produto.parcelamento > 1 && produto.disponibilidade !== 2"
                    style="font-size: 15px !important; color: #808080;"
                    class="mb-2 text-subtitle-1"
                  >
                    <v-icon
                      color="#183B94"
                    >
                      mdi-cash-multiple
                    </v-icon>
                    Até {{ produto.parcelamento }} parcelas de R$ {{ produto.desconto && !produto.nomeCampanha ? currencyFormatter( ( (produto.preco - (produto.preco * (parseFloat(produto.desconto) / 100))) / produto.parcelamento )) : currencyFormatter( ( produto.preco / produto.parcelamento )) }}
                    <v-divider
                      style="background-color: rgba(183, 194, 222, 1); height: 2px; max-height: 2px; margin-top: 5px"
                    />
                  </div>
                  <div
                    v-if="produto.entrega && (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
                    style="font-size: 15px !important; color: #808080;"
                    class="mb-2 text-subtitle-1"
                  >
                    <v-icon
                      color="#183B94"
                    >
                      mdi-truck-fast
                    </v-icon>
                    Entrega: {{ produto.entrega }} dias
                  </div>
                  <div
                    v-else-if="(this.$store.state.user.roles[0].slug === 'produtor' && produto.disponibilidade === 2) || produto.preco <= 0"
                    style="font-size: 12px !important; font-weight: 500; color: red;"
                    class="mb-2 text-subtitle-1 py-0"
                  >
                    <v-icon
                      color="red"
                    >
                      mdi-cash-usd-outline
                    </v-icon>
                    Solicite uma cotação para saber o preço deste produto.
                  </div>
                </v-card>
                <v-card
                  v-if="(this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2)"
                  elevation="0"
                >
                  <div
                    class="py-0"
                    style="height: 30px;"
                  >
                    <div v-if="produto.desconto && !produto.nomeCampanha">
                      <span class="oferta">R$ {{ currencyFormatter( produto.preco ) }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span
                        class="preco"
                      >R$ {{ currencyFormatter( produto.desconto && !produto.nomeCampanha ? (produto.preco - (produto.preco * (parseFloat(produto.desconto) / 100))) : produto.preco ) }}</span>
                    </div>
                  </div>
                </v-card>
              </v-card-text>

              <v-card-actions style="display: flex; flex-direction: column; width: 100%; gap: 10px">
                <v-btn
                  height="35px"
                  :color="checa_Produto(produto) ? '#D9D9D9' : (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2) ? 'success accent-4': 'warning accent-4'"
                  block
                  dark
                  style="border-radius: 8px;"
                  class="text-none text-white"
                  @click="muda_botao(produto)"
                >
                  {{ checa_Produto(produto) ? 'Ir para o Carrinho' : (this.$store.state.user.roles[0].slug === 'tecnico' && produto.preco > 0) || (produto.disponibilidade !== 2) ? 'Adicionar ao carrinho' : 'Cotar' }}
                  <v-icon
                    small
                    color="white"
                    class="ml-1"
                  >
                    {{ checa_Produto(produto) ? 'mdi-cart-check' : 'mdi-cart-arrow-down' }}
                  </v-icon>
                </v-btn>

                <v-btn
                  height="35px"
                  color="primary"
                  small
                  block
                  style="border-radius: 8px;"
                  class="text-none mx-0 text-white"
                  @click="handleBackButton(ref)"
                >
                  Retornar para loja
                  <v-icon
                    small
                    color="white"
                    class="ml-1"
                  >
                    mdi-backup-restore
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
            elevation="0"
            style="padding: 10px; border-radius: 8px"
          >
            <h2
              style="font-size: 26px;
              text-align: left;
              line-height: normal;
              margin: 0px;
              font-weight: 600;
              color: #183B94;
              padding: 0px 0px 0px 10px;"
            >
              Produtos Recomendados
            </h2>
            <div
              style="text-align: left;
              line-height: normal;
              color: #183B94;
              font-weight: 500;
              margin-bottom: 10px;
              padding: 10px 0px 10px 10px;"
            >
              Aproveite os mais requisitados
            </div>
            <produtos-recomendados />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2
            style="font-size: 26px;
            text-align: left;
            line-height: normal;
            margin: 0px;
            font-weight: bold;
            color: #183B94;
            padding: 0px 0px 0px 10px;"
          >
            Informações do Produto
          </h2>
        </v-col>
        <v-col
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-bottom: 50px'
              : 'margin-bottom: 0'
          "
        >
          <v-expansion-panels
            accordion
            flat
          >
            <v-expansion-panel
              v-for="(item,i) in cleanRotulo"
              :key="i"
              style="font-weight: bold; border-bottom: 1px solid lightgray;"
            >
              <v-expansion-panel-header
                style="font-size: 15px;"
                expand-icon="mdi-plus"
                disable-icon-rotate
              >
                {{ formatRotuloTitle(i) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                style="font-size: 13px; color: #808080;"
                class="text-subtitle-1"
              >
                {{ item }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <campaign-dialog />
    </v-container>
  </div>
</template>
<script>
  import { formatImageLink } from './helpers'
  import CampaignDialog from './_campanhaDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import ProdutoIndividualStore, { BOOTSTRAP } from '@/store/modules/produtoIndividual'
  import { OPEN_CAMPAIGN_DIALOG } from '@/store/modules/loja'
  import { currencyFormatter } from '../../utils/formatter'
  import { ADD_TO_CART } from '@/store/modules/carrinho'
  import ProdutosRecomendados from './ProdutosRecomendados.vue'

  export default {
    components: {
      ProdutosRecomendados,
      CampaignDialog,
    },
    data () {
      return {
        showImage: 'img/prodImage.png',
        currencyFormatter,
      }
    },
    computed: {
      ...mapState('produtoIndividual', ['produto', 'loading', 'ref']),
      ...mapState('carrinho', ['produtos']),
      ...mapState('fazendas', ['selecaoFazenda']),
      cleanRotulo () {
        return Object.fromEntries(Object.entries(this.produto.rotulo).filter(([_, v]) => (v !== '' && v !== null && v !== undefined)))
      },
    },
    watch: {
      '$route' (to, from) {
        const id = this.$route.query.id
        this.BOOTSTRAP({ id })
        this.handleImageClick(this.produto.imagens[0])
      },
    },
    async created () {
      if (!this.$store.state.produtoIndividual) { this.$store.registerModule(['produtoIndividual'], ProdutoIndividualStore) }
      if (this.$store.getters['loja/catalogoFiltered'].length === 0) {
        await this.$store.dispatch('loja/BOOTSTRAP')
      }
    },
    mounted () {
      if (this.produto && this.produto.imagens) {
        this.handleImageClick(this.produto.imagens[0])
      }
    },
    methods: {
      ...mapActions('produtoIndividual', [BOOTSTRAP]),
      ...mapActions('loja', [OPEN_CAMPAIGN_DIALOG]),
      ...mapActions('carrinho', [ADD_TO_CART]),
      handleBackButton (ref) {
        this.$router.push({ path: ref }).catch(() => {})
      },
      informacoes () {
        window.scrollTo({
          top: document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: 'smooth',
        })
      },
      handleImageClick (image) {
        this.showImage = image ? this.formatImageLink(image) : 'img/prodImage.png'
      },
      formatImageLink,
      handleCampanhaClick (produto) {
        this.showCampaignDetailsDialog(produto.id_campanha)
      },
      showCampaignDetailsDialog (id) {
        this.OPEN_CAMPAIGN_DIALOG(id)
      },
      checa_Produto (produto) {
        return (!(this.produtos.find(el => el.id === produto.id) === undefined))
      },
      muda_botao (produto) {
        if (this.checa_Produto(produto)) {
          this.$router.push({ path: `/${this.$user.get().role}/loja/carrinho` })
        } else {
          this.ADD_TO_CART(produto).then((response) => {
            // if (response) { this.$router.push({ path: `/${this.$user.get().role}/loja/carrinho` }) }
          })
        }
      },
      formatRotuloTitle (titulo) {
        switch (titulo) {
          case 'indicacao': return 'Indicação'
          case 'modo_uso': return 'Modo de Uso'
          case 'restricoes': return 'Restrições'
          case 'consumo': return 'Consumo'
          case 'validade': return 'Validade'
          case 'apresentacao': return 'Apresentação'
          default: return ''
        }
      },
    },
  }
</script>
<style scoped>
.oferta {
  font-size: 24px;
  font-weight: 400;
  color: #808080;
  text-decoration: line-through;
}

.preco {
  font-size: 30px;
  font-weight: 600;
  color: #143693;
}

.triangulo {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
}
.triangulo::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    background-color: red;
    transform: rotate(-44deg);
    transform-origin: left bottom;
}

.cotacao {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
}
.cotacao::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    background-color: #ff8f00;
    transform: rotate(-44deg);
    transform-origin: left bottom;
}
</style>
